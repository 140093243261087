import React, { memo, useState } from "react";
import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";
import { get } from "lodash";
import axios from "axios";
import { defaultParams } from "../../common/hooks/useGetUtm";

const defaultValue = {
  name: "",
  email: "",
  phone: "",
  note: "",
  shop: "",
};

function ModalForm({ isOpen, onClose, lang }) {
  const [form, setForm] = useState(defaultValue);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { allStrapiHomePage } = useStaticQuery(graphql`
    query {
      allStrapiHomePage {
        nodes {
          locale
          signupForm {
            title
            strapi_fields {
              name
              placeholder
              type
              id
              require
            }
          }
        }
      }
    }
  `);
  const formData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const urlSearchParams = !!window.location.search
      ? new URLSearchParams(window.location.search)
      : "";
    const params = !!urlSearchParams
      ? Object.fromEntries(urlSearchParams.entries())
      : defaultParams;

    try {
      const response = await axios.post(
        "https://cms.kaio.ai/api/signup-forms",
        {
          data: {
            ...form,
            ...params,
          },
        }
      );
      toast({
        title: "Thank you for your interest in Kaio. We will contact you soon.",
        status: "success",
        position: "top-right",
      });
    } catch (err) {
      toast({
        title: "Có lỗi xảy ra",
        status: "error",
        position: "top-right",
      });
    } finally {
      onClose();
      setLoading(false);
      setForm(defaultValue);
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderField = (item) => {
    switch (item.type) {
      case "text":
        return (
          <Input
            key={item.id}
            placeholder={item.placeholder}
            name={item.name}
            mb="1rem"
            isRequired={item.require}
            required={item.require}
            onChange={handleChange}
            value={form[item.name]}
          />
        );
      case "email":
        return (
          <Input
            key={item.id}
            placeholder={item.placeholder}
            name={item.name.toLowerCase()}
            isRequired={item.require}
            type="email"
            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            mb="1rem"
            onChange={handleChange}
            value={form[item.name]}
          />
        );
      case "tel":
        return (
          <Input
            key={item.id}
            placeholder={item.placeholder}
            name={item.name}
            isRequired={item.require}
            type="tel"
            pattern="[0-9]{10}"
            mb="1rem"
            onChange={handleChange}
            value={form[item.name]}
          />
        );
      case "textarea":
        return (
          <Textarea
            key={item.id}
            placeholder={item.placeholder}
            name={item.name}
            rows={4}
            resize="none"
            bgColor="#fff"
            onChange={handleChange}
            value={form[item.name]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent padding="20px">
        <Flex justifyContent="space-between" alignItems="center" mb="20px">
          <Text fontWeight="bold" fontSize={24}>
            {get(formData, "signupForm.title", "")}
          </Text>
          <ModalCloseButton size="sm" />
        </Flex>
        <ModalBody padding={0}>
          <form onSubmit={handleSubmit}>
            {get(formData, "signupForm.strapi_fields", [])?.map(renderField)}
            <Button
              type="submit"
              w="full"
              color="#fff"
              bgColor="primary"
              _hover={{}}
              isLoading={loading}
              my="20px"
            >
              Đăng kí dùng thử ngay
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default memo(ModalForm);
