import React from "react";
import { Icon } from "react-icons-kit";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Link as CLink, Text as CText } from "@chakra-ui/react";
import { ic_place } from "react-icons-kit/md/ic_place";
import { ic_phone } from "react-icons-kit/md/ic_phone";
import { paperPlane } from "react-icons-kit/fa/paperPlane";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Text from "common/components/Text";
import {
  Section,
  Grid,
  AboutUs,
  FooterWidget,
  ContactInfo,
  InfoItem,
  FooterBottom,
  FooterNav,
  SocialLinks,
} from "./footer.style";
import { get } from "lodash";

const Footer = ({ lang }) => {
  const { allStrapiHomePage } = useStaticQuery(graphql`
    {
      allStrapiHomePage {
        nodes {
          locale
          footer {
            about {
              logo {
                url
                alternativeText
              }
              text
            }
            contactInfo {
              address
              email
              title
              phone
            }
            copyright
            nav {
              id
              link
              title
            }
            socialLinks {
              link
              id
              icon {
                alternativeText
                url
              }
            }
            widgets {
              id
              title
              lists {
                id
                link
                title
              }
            }
          }
        }
      }
    }
  `);
  const footerData = allStrapiHomePage?.nodes?.filter(
    (node) => node.locale === lang
  )[0];
  return (
    <Section>
      <Container width="1400px">
        <Grid>
          <AboutUs>
            <Image
              src={get(footerData, "footer.about.logo.url", "")}
              alt={get(footerData, "footer.about.logo.alternativeText", "")}
            />
            <CText color="white" fontSize={16}>
              {get(footerData, "footer.about.text", "")}
            </CText>
          </AboutUs>
          {get(footerData, "footer.widgets", [])?.map((item) => (
            <FooterWidget key={item.id}>
              <h4>{item?.title}</h4>
              <ul>
                {item?.lists?.map((item2) => (
                  <li className="widgetListItem" key={item2.id}>
                    <CLink
                      as={Link}
                      color="#fff"
                      to={item2?.link ?? ""}
                      isExternal
                    >
                      {item2?.title}
                    </CLink>
                  </li>
                ))}
              </ul>
            </FooterWidget>
          ))}
          <ContactInfo>
            <Heading
              as="h4"
              content={get(footerData, "footer.contactInfo.title", "")}
            />
            <InfoItem>
              <Icon icon={ic_place} size={24} />
              <Text
                content={get(footerData, "footer.contactInfo.address", "")}
              />
            </InfoItem>
            <InfoItem>
              <Icon icon={ic_phone} size={26} className="phone-icon" />
              <Text
                className="phone-number"
                content={get(footerData, "footer.contactInfo.phone", "")}
              />
            </InfoItem>
            <InfoItem>
              <Icon icon={paperPlane} size={22} />
              <Text content={get(footerData, "footer.contactInfo.email", "")} />
            </InfoItem>
          </ContactInfo>
        </Grid>
      </Container>
      <Container width="1400px">
        <FooterBottom>
          <Text content={get(footerData, "footer.copyright", "")} />
          <FooterNav>
            {get(footerData, "footer.nav", [])?.map((item) => (
              <li key={item.id}>
                <Link to={item?.link} target="_blank">
                  {item.title}
                </Link>
              </li>
            ))}
          </FooterNav>
          <SocialLinks>
            <span>Social:</span>
            <ul>
              {get(footerData, "footer.socialLinks", [])?.map((item) => (
                <li key={item.id}>
                  <Link to={item?.link} target="_blank">
                    <img
                      src={item?.icon?.url}
                      alt={item?.icon?.alternativeText}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </SocialLinks>
        </FooterBottom>
      </Container>
    </Section>
  );
};

export default Footer;
