import React, { useEffect } from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import Flag from "react-flagkit";
import { Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { useState } from "react";

const LanguagePicker = ({ supportedLanguages }) => {
  const { originalPath, language } = useI18next();
  const [languageSelected, setLanguage] = useState("");

  useEffect(() => {
    const lang = supportedLanguages.filter(
      (lang) => lang.language === language
    )[0];
    setLanguage(lang?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Menu autoSelect={false}>
      <MenuButton mx="10px">
        <Flag country={languageSelected} size={40} />
      </MenuButton>
      <MenuList>
        {supportedLanguages.map((item) => (
          <Link
            aria-label={`Change language to ${item?.name}`}
            to={originalPath}
            language={item.language}
            key={item.language}
          >
            <MenuItem>
              <Flag country={item.name} size={40} />
              <Text pl="5px">{item.name}</Text>
            </MenuItem>
          </Link>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguagePicker;
