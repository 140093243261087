import React, { useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import NavbarWrapper from "common/components/Navbar";
import Drawer from "common/components/Drawer";
import Button from "common/components/Button";
import Logo from "common/components/UIElements/Logo";
import Box from "common/components/Box";
import HamburgMenu from "common/components/HamburgMenu";
import Container from "common/components/UI/Container";
import { DrawerContext } from "common/contexts/DrawerContext";
import ScrollSpyMenu from "common/components/ScrollSpyMenu";
import LanguagePicker from "common/components/LanguagePicker";
import { get } from "lodash";
import ModalForm from "../../../components/ModalForm/ModalForm";
import { useDisclosure } from "@chakra-ui/react";

const Navbar = ({
  navbarStyle,
  logoStyle,
  row,
  menuWrapper,
  lang,
  showItems,
}) => {
  const { state, dispatch } = useContext(DrawerContext);

  const { isOpen, onClose, onOpen } = useDisclosure();
  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    });
  };

  const { allStrapiHomePage } = useStaticQuery(graphql`
    query {
      allStrapiHomePage {
        nodes {
          locale
          header {
            navbar {
              name
              url
              target
            }
            languages {
              name
              language
            }
            logo {
              image {
                url
              }
              alt
            }
            button {
              url
              name
            }
          }
        }
      }
    }
  `);

  const cmsData = allStrapiHomePage.nodes.filter(
    (node) => node.locale === lang
  )[0];

  const menus = !showItems
    ? get(cmsData, "header.navbar", [])
    : get(cmsData, "header.navbar", []).filter((item) =>
        item.url.includes(showItems)
      );

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container width="1400px">
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={get(cmsData, "header.logo.image.url", "")}
            title="Kaio"
            logoStyle={{
              maxWidth: ["80px", "100px"],
            }}
            className="main-logo"
          />
          <Box {...menuWrapper} className="mainMenuWrapper">
            <ScrollSpyMenu
              className="main_menu"
              menuItems={menus}
              offset={-70}
              disabledAnchor={!!showItems}
            />
            <Link
              target="_blank"
              className="navbar_button navbar_button_one"
              to="#"
            >
              <Button
                title={get(cmsData, "header.button.name", "")}
                onClick={onOpen}
              />
            </Link>

            <LanguagePicker
              className="language_picker"
              supportedLanguages={get(cmsData, "header.languages", [])}
            />
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#108AFF" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={get(cmsData, "header.navbar", [])}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          </Box>
        </Box>
        <ModalForm isOpen={isOpen} onClose={onClose} lang={lang} />
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    className: "web_app_creative_navbar",
    minHeight: "70px",
    display: "block",
  },
  row: {
    flexBox: true,
    alignItems: "center",
    width: "100%",
  },
  logoStyle: {
    maxWidth: ["116px", "116px"],
  },
  button: {},
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  },
};

export default Navbar;
