import { rgba } from "polished";

const colors = {
  transparent: "transparent", // 0
  black: "#000000", // 1
  white: "#ffffff", // 2
  headingColor: "#0F2137", // 3
  textColor: rgba("#0F2137", 0.65), // 4
  textColorAlt: "#36526C", // 5
  textColorLight: rgba("#fff", 0.7), // 6
  labelColor: "#767676", // 7
  inactiveField: "#f2f2f2", // 8
  inactiveButton: "#b7dbdd", // 9
  inactiveIcon: "#EBEBEB", // 10
  primary: "#2C6FEE", // 11
  primaryHover: "#3C74FF", // 12
  secondary: "#FF9B3E", // 13
  borderColor: "#E5ECF4", //14
  linkColor: "#2C6FEE", // 15
  primaryBoxShadow: "0px 8px 20px -6px rgba(42, 162, 117, 0.57)",
  secondaryBoxShadow: "0px 8px 20px -6px rgba(237, 205, 55, 0.5)",
};

export default colors;
